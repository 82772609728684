import { create } from 'zustand';
import type { Attachment } from 'ai';

interface AttachmentsState {
  // Store state
  attachments: Array<Attachment>;
  uploadQueue: Array<string>;

  // Actions
  setAttachments: (attachments: Array<Attachment>) => void;
  addAttachments: (newAttachments: Array<Attachment>) => void;
  removeAttachment: (attachmentToRemove: Attachment) => void;
  clearAttachments: () => void;

  setUploadQueue: (queue: Array<string>) => void;
  addToUploadQueue: (filename: string) => void;
  removeFromUploadQueue: (filename: string) => void;
  clearUploadQueue: () => void;
}

export const useAttachmentsStore = create<AttachmentsState>(set => ({
  // Initial state
  attachments: [],
  uploadQueue: [],

  // Attachment actions
  setAttachments: attachments => set({ attachments }),

  addAttachments: newAttachments =>
    set(state => ({
      attachments: [...state.attachments, ...newAttachments],
    })),

  removeAttachment: attachmentToRemove =>
    set(state => ({
      attachments: state.attachments.filter(
        attachment =>
          !(
            attachment.url === attachmentToRemove.url &&
            attachment.name === attachmentToRemove.name
          ),
      ),
    })),

  clearAttachments: () => set({ attachments: [] }),

  // Upload queue actions
  setUploadQueue: queue => set({ uploadQueue: queue }),

  addToUploadQueue: filename =>
    set(state => ({
      uploadQueue: [...state.uploadQueue, filename],
    })),

  removeFromUploadQueue: filename =>
    set(state => ({
      uploadQueue: state.uploadQueue.filter(name => name !== filename),
    })),

  clearUploadQueue: () => set({ uploadQueue: [] }),
}));
