import { SandpackFiles } from '@codesandbox/sandpack-react';
import { ImageReference, ImageChanges } from '@/types/image';

export class ImageTracker {
  private previousImages: Map<string, Set<string>> = new Map();

  private readonly SCANNABLE_EXTENSIONS = [
    '.tsx',
    '.jsx',
    '.mdx',
    '.css',
    '.scss',
    '.module.css',
    '.module.scss',
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.svg',
    '.webp',
    '.ts',
    '.js',
  ];

  private readonly IMAGE_PATTERNS = {
    import: /import\s+.*?from\s+['"](.+?\.(?:png|jpg|jpeg|gif|svg|webp))['"]/g,
    require: /require\(['"](.+?\.(?:png|jpg|jpeg|gif|svg|webp))['"]\)/g,
    src: /src=["']([^"']+(?:(?:\.(?:png|jpg|jpeg|gif|svg|webp))|(?:unsplash\.com)|(?:cloudinary\.com)|(?:imgur\.com))[^"']*)["']/gi,
    url: /url\(['"]?(.+?\.(?:png|jpg|jpeg|gif|svg|webp))['"]?\)/g,
    imgTag: /<img\s+[^>]*?src=["']([^"']+)["'][^>]*?>/gi,
    objectSrc:
      /src:\s*["']([^"']+(?:(?:\.(?:png|jpg|jpeg|gif|svg|webp))|(?:unsplash\.com)|(?:cloudinary\.com)|(?:imgur\.com))[^"']*)["']/gi,
    directUrl: /https?:\/\/[^"'\s)]+\.(?:png|jpg|jpeg|gif|svg|webp|avif)/gi,
    unsplashUrl: /https?:\/\/(?:.*\.)?unsplash\.com\/[^"'\s)]+/gi,
    imageVariable:
      /(?:avatar|image|img|photo|picture)\s*(?:=|:)\s*["']([^"']+)["']/gi,
    arrayLiteral:
      /["'](https?:\/\/[^"'\s)]+\.(?:png|jpg|jpeg|gif|svg|webp|avif))["']/gi,
    objectLiteral:
      /image:\s*["'](https?:\/\/[^"'\s)]+\.(?:png|jpg|jpeg|gif|svg|webp|avif))["']/gi,
  };

  private shouldScanFile(filePath: string): boolean {
    const ext = filePath.substring(filePath.lastIndexOf('.')).toLowerCase();
    return this.SCANNABLE_EXTENSIONS.includes(ext);
  }

  private getFileContent(fileData: string | { code: string }): string {
    return typeof fileData === 'string' ? fileData : fileData.code;
  }

  findImageReferences(files: SandpackFiles): ImageReference[] {
    const imageRefs: ImageReference[] = [];

    Object.entries(files).forEach(([filePath, fileData]) => {
      if (!this.shouldScanFile(filePath)) {
        return;
      }

      const code = this.getFileContent(fileData);
      const lines = code.split('\n');

      lines.forEach((line, index) => {
        Object.entries(this.IMAGE_PATTERNS).forEach(([type, pattern]) => {
          let match;
          while ((match = pattern.exec(line)) !== null) {
            const imagePath =
              type === 'directUrl' ||
              type === 'unsplashUrl' ||
              type === 'arrayLiteral' ||
              type === 'objectLiteral'
                ? match[0].replace(/['"]/g, '')
                : match[1];

            if (imagePath && this.isValidImageUrl(imagePath)) {
              imageRefs.push({
                filePath,
                imagePath,
                type: type as any,
                lineNumber: index + 1,
                timestamp: Date.now(),
              });
            }
          }
        });
      });
    });

    return imageRefs;
  }

  private isValidImageUrl(url: string): boolean {
    return (
      url.startsWith('http') ||
      url.startsWith('/') ||
      /\.(png|jpg|jpeg|gif|svg|webp|avif)$/i.test(url)
    );
  }

  getImageChanges(files: SandpackFiles): ImageChanges {
    const currentRefs = this.findImageReferences(files);
    const changes: ImageChanges = {
      added: [],
      removed: [],
      timestamp: Date.now(),
    };

    // Check for added images
    currentRefs.forEach(ref => {
      const previousFileImages =
        this.previousImages.get(ref.filePath) || new Set();
      if (!previousFileImages.has(ref.imagePath)) {
        changes.added.push(ref);
      }
    });

    // Check for removed images
    this.previousImages.forEach((prevImages, filePath) => {
      const currentFileRefs = currentRefs.filter(
        ref => ref.filePath === filePath,
      );
      const currentFilePaths = new Set(
        currentFileRefs.map(ref => ref.imagePath),
      );

      prevImages.forEach(prevImage => {
        if (!currentFilePaths.has(prevImage)) {
          changes.removed.push({
            filePath,
            imagePath: prevImage,
            type: 'import' as const,
            lineNumber: -1,
            timestamp: Date.now(),
          });
        }
      });
    });

    // Update previous state
    this.previousImages.clear();
    currentRefs.forEach(ref => {
      const fileImages = this.previousImages.get(ref.filePath) || new Set();
      fileImages.add(ref.imagePath);
      this.previousImages.set(ref.filePath, fileImages);
    });

    return changes;
  }

  reset(): void {
    this.previousImages.clear();
  }
}
