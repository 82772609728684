export enum MIX_PANEL_EVENTS {
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  PRD_TEXT_HIGHLIGHT = 'PRD_TEXT_HIGHLIGHT',
  API_REQUEST = 'API_REQUEST',
  API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS',
  API_ERROR = 'API_ERROR',
  WIREFRAME_FEATURE_DELETE = 'WIREFRAME_FEATURE_DELETE',
  USER_LOGIN = 'USER_LOGIN',
  NEW_USER_LOGIN = 'NEW_USER_LOGIN',
}

export enum POSTHOG_EVENTS {
  SIDEBAR_CLICK = 'SIDEBAR_CLICK', // Whenever the user clicks on any item in the sidebar
  LOGO_CLICK = 'LOGO_CLICK', // Whenever the user clicks on the creatr logo on the top left to go to home
  SWITCH_FILTER = 'SWITCH_FILTER', // Whenever the user switches the filter chips on the dashboard to sort the projects
  CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT', // Track whenever a new project is created
  LEARN_CREDITS = 'LEARN_CREDITS', // When the user clicks on the learn credits button on the payment modal
  PLATFORM_SWITCH = 'PLATFORM_SWITCH', // When the user switches the platform on the dashboard input
  FLOW_TYPE_SWITCH = 'FLOW_TYPE_SWITCH', // When the user switches the flow type on the dashboard input
  EXAMPLE_PROMPT_CLICK = 'EXAMPLE_PROMPT_CLICK', // When the user clicks on the example prompt on the dashboard input
  /**
   * Description: All Events related to editing a screen
   * Type: Single Screen or Single Flow
   * Prompt: Edit Screen
   * Flow ID: xxx
   * Action: Edit Component or Edit Image or Edit Text
   * Old Text: xxx
   * New Text: xxx
   */
  EDIT_SCREEN = 'EDIT_SCREEN',
  /**
   * Description: All Events related to generating a layout for a screen
   * Action: Generate or Replace
   * Component: xxx
   * Type: Single Component or Multiple Components
   */
  DESIGN_LAYOUT = 'DESIGN_LAYOUT',
  /**
   * Description: All Events related to deleting, duplicating, moving up or moving down a component
   * Action: Delete, Duplicate, Move Up, Move Down
   * Type: Single Screen or Single Flow
   * Component: xxx
   */
  COMPONENT_ACTION = 'COMPONENT_ACTION',
  /**
   * Description: All Events related to adding a component to a screen
   * Action: Generate Component Click or Submit
   * Position: Up or Down
   * Original Component: xxx
   * Prompt: xxx
   * Submission: Insert or Generate
   */
  ADD_COMPONENT = 'ADD_COMPONENT',
  /**
   * Description: All Events related to editing a component
   * Type: Single Screen or Single Flow
   * New Component: xxx
   * Old Component: xxx
   */
  INTRA_COMPONENT = 'INTRA_COMPONENT',
  /**
   * Description: All Events related to exporting a screen to figma
   * Type: Single Screen or Single Flow
   * Action: Export Button Click or Submit
   */
  EXPORT_TO_FIGMA = 'EXPORT_TO_FIGMA',
  /**
   * Description: All Events related to sharing a project
   * Type: Single Screen or Single Flow
   * Action: Share Button Click or Submit
   * Medium: Copy Link or Facebook or Twitter or Instagram or Whatsapp or Telegram
   */
  SHARE_PROJECT = 'SHARE_PROJECT',
  /**
   * Description: All Events related to showing the QR code for a project
   * Type: Single Screen or Single Flow
   */
  QR_CODE_SHOW = 'QR_CODE_SHOW',
  /**
   * Description: All Events related to showing the feedback button on a screen
   * Type: Single Screen or Single Flow
   * Rating: 1, 2, 3, 4, 5
   * Feedback: xxx
   * Submitted: true or false
   */
  FEEDBACK_BUTTON_CLICK = 'FEEDBACK_BUTTON_CLICK',
  /**
   * Description: All Events related to switching the left panel tabs
   * Tab: Layers or Assets
   */
  LEFT_PANEL_TAB_SWITCH = 'LEFT_PANEL_TAB_SWITCH',
  /**
   * Description: All Events related to clicking on the right panel tabs
   * Tab: Edit or Layout or Code or History
   * SubTab: Edit Component or Edit Screen / Component Layouts or Section Layouts
   */
  RIGHT_PANEL_TAB_SWITCH = 'RIGHT_PANEL_TAB_SWITCH',
  /**
   * Description: All Events related to clicking on the layers tab on the left panel
   * Target: Screen or Layer
   * Target Name: xxx
   */
  LAYERS_CLICK = 'LAYERS_CLICK',
  /**
   * Description: All Events related to selecting a component on the right panel
   * Type: Single Screen or Single Flow
   * Component: xxx
   * Selected: Layer or Component
   * MultiSelect: true or false
   */
  SELECT_COMPONENT = 'SELECT_COMPONENT',
  /**
   * Description: All Events related to clicking on the code actions on the right panel
   * Action: Copy or Codepen
   * Type: Single Screen or Single Flow
   */
  CODE_ACTIONS = 'CODE_ACTIONS',
  /**
   * Description: All Events related to clicking on the version history on the right panel
   * Action: Download or Reset to this version
   * Type: Single Screen or Single Flow
   */
  VERSION_HISTORY = 'VERSION_HISTORY',
  /**
   * Description: Enhance Prompts
   * Type: Dashboard or Chat
   * WebId: xxx
   * Prompt: xxx
   */
  ENHANCE_PROMPT = 'ENHANCE_PROMPT',
  /**
   * Description: All Events related to sending a message in the chat
   * WebId: xxx
   * Prompt: xxx
   */
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  /**
   * Description: All Events related to toggling the chat on the workspace
   * WebId: xxx
   * Toggle: Chat or Version History
   */
  CHAT_TOGGLE = 'CHAT_TOGGLE',
  /**
   * Description: All Events related to toggling the preview on the workspace
   * WebId: xxx
   * Toggle: Preview or Code
   */
  PREVIEW_TOGGLE = 'PREVIEW_TOGGLE',
  /**
   * Description: All Events related to clicking on the header on the workspace
   * WebId: xxx
   * Type: Open or Save or Close
   * Origin: Dashboard or Chat
   */
  KNOWLEDGEBASE_MODAL = 'KNOWLEDGEBASE_MODAL',
  /**
   * Description: All Events related to clicking on the export modal on the workspace
   * WebId: xxx
   * Type: Open or Initiate Export or Download ZIP
   */
  EXPORT_MODAL = 'EXPORT_MODAL',
  /**
   * Description: All Events related to clicking on the deploy button on the workspace
   * WebId: xxx
   * Type: Open or Deploy or Redeploy
   * Link: Open or Open - Modal or Copy or Custom Domain
   */
  DEPLOY_PROJECT = 'DEPLOY_PROJECT',
  /**
   * Description: All Events related to clicking on the fork button on the workspace
   * WebId: xxx
   * Type: Open or Fork
   */
  FORK_PROJECT = 'FORK_PROJECT',
  /**
   * Description: All Events related to clicking on the refresh button on the preview toolbar
   * WebId: xxx
   * Type: Refresh, Copy, Open External, Toggle Inspection
   * State: On or Off
   */
  PREVIEW_EVENTS = 'PREVIEW_EVENTS',
  /**
   * Description: All Events related to clicking on the device selector on the preview toolbar
   * WebId: xxx
   * Type: Mobile, Tablet, Desktop
   */
  DEVICE_SELECTOR = 'DEVICE_SELECTOR',
  /**
   * Description: All Events related to clicking on the share dialog on the preview toolbar
   * WebId: xxx
   * Type: Open or Copy or Twitter
   */
  SHARE_DIALOG = 'SHARE_DIALOG',
  /**
   * Description: All Events related to renaming a project
   * WebId: xxx
   * Old Name: xxx
   * New Name: xxx
   */
  RENAME_PROJECT = 'RENAME_PROJECT',
  /**
   * Description: All Events related to clicking on the logo on the top left to navigate to the home page
   * WebId: xxx
   */
  LOGO_NAVIGATE = 'LOGO_NAVIGATE',
}

enum DEPRECATED_POSTHOG_EVENTS {
  // Deprecated
  SHOW_CREDITS = 'SHOW_CREDITS', // Whenever the user clicks on the credits button on the top bar
  // Deprecated
  TOGGLE_AVATAR = 'TOGGLE_AVATAR', // Whenever the user clicks on the avatar on the top right
  // Deprecated
  LOGOUT = 'LOGOUT', // Whenever the user clicks on the logout button on the top right
  // Deprecated
  OPEN_PROJECT = 'OPEN_PROJECT', // Whenever the user clicks on a project to open it
  // Deprecated
  PROCEED_WITH_PAYMENT = 'PROCEED_WITH_PAYMENT', // Whenever the user clicks on the proceed with payment button on the payment modal after entering credits amount
  // Deprecated
  INCREASE_CREDIT_CHIP = 'INCREASE_CREDIT_CHIP', // Whenever the user clicks on the increase credit chip on the payment modal
  // Deprecated
  PAYMENT_ACTION = 'PAYMENT_ACTION', // Whenever the user clicks on the payment action button on the payment modal (can be stripe or succeesful)
  // Deprecated
  SCHEDULE_CALL = 'SCHEDULE_CALL', // When the user clicks to schedule a call for free credits
  // Deprecated
  GENERATE_OBJECTIVE = 'GENERATE_OBJECTIVE', // When the user clicks on the generate objective button on the dashboard input
}
