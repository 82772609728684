import { ElementMetadata } from '@/components/WebappPreviewIframe';
import { create } from 'zustand';
import { io, type Socket } from 'socket.io-client';
import { MutableRefObject } from 'react';
import { ImageTracker } from '@/utils/imageTracker';
import { ImageChanges, ElementImageMapping } from '@/types/image';
import { ElementVideoMapping } from '@/types/video';

export enum ProjectCreationSteps {
  PROMPT = 'PROMPT',
  QUESTIONS = 'QUESTIONS',
  ANALYSIS = 'ANALYSIS',
  CLONE_AND_SETUP_REPO = 'SEEDLING',
  INSTALL_DEPENDENCIES = 'NOURISH',
  BUILD_INITIAL_PROJECT = 'FOUNDATION',
  RUN_LLM_EDIT = 'ARCHITECT',
  BUILD_PROJECT = 'BUILD_PROJECT',
  UPLOAD_TO_S3 = 'SKYWARD',
  FINAL_BUILD = 'ZENITH',
  UPLOAD_LATEST_CODE = 'ARCHIVE',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  INTEGRATE_COMPONENT = 'INTEGRATE_COMPONENT',
  CHAT_START = 'CHAT_START',
}

export enum SubPhase {
  CODING = 'CODING',
  EDITING = 'EDITING',
  INSTALLING = 'INSTALLING',
  TESTING = 'TESTING',
  CHAT = 'CHAT',
}

export interface ChatMessage {
  _id: string;
  role: 'user' | 'assistant';
  content: string;
  commitHash?: string;
  commitMessage?: string;
}

// Add TokenStats interface
export interface TokenStats {
  totalTokens: number;
  promptTokens: number;
  completionTokens: number;
  openAITokens: number;
  totalCost: number;
}

export interface Suggestion {
  _id: string;
  title: string;
  description: string;
}

export interface WebappState {
  isWebappFetching: boolean;
  data: {
    projectName: string;
    phase: ProjectCreationSteps;
    type: string;
    subdomain: string;
    link: string;
    prodLink: string;
    knowledgeBase: string;
    _id: string;
    prompt: string;
    email: string;
    questions: any[];
    currentCommitHash: string;
    chatHistory: ChatMessage[];
    created: string;
    updated: string;
    deployments: any[];
    subPhase: SubPhase;
    errorCount: number;
    isGeneration: boolean;
    isPrivate?: boolean;
    githubRepoUrl?: string;
    integrations?: {
      supabase?: {
        ref: string;
        organization_id: string;
        name: string;
        region: string;
        auto_accept_migrations?: boolean;
        migrations?: {
          id: string;
          status: 'PENDING' | 'APPLIED' | 'DISCARDED';
          appliedAt?: Date;
          discardedAt?: Date;
          script: string;
          error?: string;
          logs?: { message: string; timestamp: Date };
        }[];
      };
      resend?: {
        enabled?: boolean;
        apiKey: string;
        domain?: string;
        fromAddress?: string;
      };
      mapbox?: {
        apiKey?: string;
        enabled?: boolean;
        using?: 'creatr' | 'personal';
      };
      openai?: {
        enabled?: boolean;
      };
    };
    /////
    __v: number;
    platform: 'web' | 'component';
    generation404?: {
      isActive: boolean;
      componentName: string;
      url: string;
      metadata: ElementMetadata;
    };
    currentNavigationUrl?: string;
  } | null;
  isLoading: boolean;
  isInspecting: boolean;
  isImageInspecting: boolean;
  inspectedElements: ElementMetadata[];
  refetchWebapp: number;
  isOwner: boolean;
  socket: Socket | null;
  isConnected: boolean;
  files: {} | null;
  isRemoteUpdate: boolean;
  iframeRef: MutableRefObject<HTMLIFrameElement | null> | null;
  deployedCommitHash: string;
  selectedVersion: {
    _id: string;
    commitHash: string;
    webId?: string;
  } | null;
  getMigrations: () =>
    | {
        id: string;
        status: 'PENDING' | 'APPLIED' | 'DISCARDED';
        appliedAt?: Date;
        discardedAt?: Date;
        script: string;
        error?: string;
        logs?: { message: string; timestamp: Date };
      }[]
    | [];
  isDraggingFile: boolean;
  sandpackState: {
    visibleFiles: string[];
    activeFile: string;
  } | null;
  handleGenerate404: (data: {
    componentName: string;
    url: string;
    metadata: ElementMetadata;
  }) => void;
  resetGenerate404: () => void;
  setIsWebappFetching: (a: boolean) => void;
  setAutoAcceptMigrations: (autoAccept: boolean) => void;
  getAutoAcceptMigrationsValue: () => boolean;
  editNavigationUrl?: string;
  isSupabasePopupOpen: boolean;
  setIsSupabasePopupOpen: (isOpen: boolean) => void;
  isResendPopupOpen: boolean;
  setIsResendPopupOpen: (isOpen: boolean) => void;
  tokenStats: TokenStats | null;
  imageTracker: ImageTracker;
  imageChanges: ImageChanges | null;
  isImageInCode: boolean;
  hasUserAppliedImage: boolean;
  previewingVersionUrl: string | null;
  selectedImageMappings: ElementImageMapping[];
  pendingTabChange: 'chat' | 'history' | null;
  showDiscardDialog: boolean;
  selectedVideoMappings: ElementVideoMapping[];
  isHovering: boolean;
  activeIntegrations: string[];
  suggestions: Suggestion[];
  lastAssistantMessageId: string | null;
  mode: 'build' | 'chat';
}

interface WebappActions {
  setData: (data: WebappState['data']) => void;
  setIsInspecting: (isInspecting: boolean) => void;
  setIsImageInspecting: (isImageInspecting: boolean) => void;
  setInspectedElements: (elements: ElementMetadata[]) => void;
  setRefetchWebapp: (refetch: number) => void;
  updateData: (updates: Partial<WebappState['data']>) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsOwner: (isOwner: boolean) => void;
  setSocket: (socket: Socket | null) => void;
  setIsConnected: (isConnected: boolean) => void;
  setFiles: (files: {} | null) => void;
  updateFile: (filePath: string, content: string) => void;
  setIframeRef: (ref: MutableRefObject<HTMLIFrameElement | null>) => void;
  currentCommitHash: string;
  setCurrentCommitHash: (hash: string) => void;
  setDeployedCommitHash: (hash: string) => void;
  setSelectedVersion: (version: WebappState['selectedVersion']) => void;
  addInspectedElement: (element: ElementMetadata) => void;
  removeInspectedElement: (element: ElementMetadata) => void;
  clearInspectedElements: () => void;
  updateSupabaseMigrationStatus: (
    migrationId: string,
    status: 'APPLIED' | 'DISCARDED',
  ) => void;
  setSupabaseProjectRef: ({
    ref,
    organization_id,
    name,
    region,
  }: {
    ref: string;
    organization_id: string;
    name: string;
    region: string;
  }) => void;
  setNewMigrations: (
    migrations: {
      id: string;
      status: 'PENDING' | 'APPLIED' | 'DISCARDED';
      script: string;
    }[],
  ) => void;
  setIsDraggingFile: (isDragging: boolean) => void;
  setSandpackState: (
    state: { visibleFiles: string[]; activeFile: string } | null,
  ) => void;
  resetSandpackState: () => void;
  setEditNavigationUrl: (url?: string) => void;
  setPhase: (phase: ProjectCreationSteps) => void;
  setTokenStats: (stats: TokenStats | null) => void;
  setImageChanges: (changes: ImageChanges | null) => void;
  checkImageChanges: () => void;
  setIsImageInCode: (hasImages: boolean) => void;
  setHasUserAppliedImage: (hasApplied: boolean) => void;
  setPreviewingVersionUrl: (url: string | null) => void;
  setUrl: (url: string) => void;
  setSelectedImageMappings: (
    mappings:
      | ElementImageMapping[]
      | ((prev: ElementImageMapping[]) => ElementImageMapping[]),
  ) => void;
  setPendingTabChange: (tab: 'chat' | 'history' | null) => void;
  setShowDiscardDialog: (show: boolean) => void;
  setSelectedVideoMappings: (
    mappings:
      | ElementVideoMapping[]
      | ((prev: ElementVideoMapping[]) => ElementVideoMapping[]),
  ) => void;
  setIsHovering: (isHovering: boolean) => void;
  isResendEnabled: boolean;
  setIsResendEnabled: (isEnabled: boolean) => void;
  setResendIntegration: (
    apiKey: string,
    domain?: string,
    fromAddress?: string,
  ) => void;
  setActiveIntegrations: (integrations: string[]) => void;
  setPrivacyStatus: (isPrivate: boolean) => void;
  setSuggestions: (suggestions: Suggestion[]) => void;
  setLastAssistantMessageId: (lastAssistantMessageId: string | null) => void;
  setMode: (mode: 'build' | 'chat') => void;
}

// Keep track of active connections
const activeConnections = new Map<string, Socket>();

export const useWebappStore = create<WebappState & WebappActions>(
  (set, get) => ({
    isWebappFetching: false,
    data: null,
    isLoading: true,
    isInspecting: false,
    isImageInspecting: false,
    inspectedElements: [],
    refetchWebapp: 0,
    isOwner: true,
    socket: null,
    isConnected: false,
    files: null,
    isRemoteUpdate: false,
    iframeRef: null,
    isResendEnabled: get()?.data?.integrations?.resend?.enabled ?? false,
    setIsWebappFetching: isWebappFetching => set({ isWebappFetching }),
    setIsResendEnabled: isEnabled => {
      set(state => {
        if (!state.data?.integrations?.resend) return state;
        return {
          data: state.data
            ? {
                ...state.data,
                integrations: {
                  ...state.data.integrations,
                  resend: {
                    ...state.data.integrations.resend,
                    enabled: isEnabled,
                  },
                },
              }
            : null,
        };
      });
    },
    isResendPopupOpen: false,
    setIsResendPopupOpen: isOpen => set({ isResendPopupOpen: isOpen }),
    setResendIntegration: (
      apiKey: string,
      domain?: string,
      fromAddress?: string,
    ) => {
      set(state => {
        if (!state.data?.integrations?.resend) return state;
        return {
          data: state.data
            ? {
                ...state.data,
                integrations: {
                  ...state.data.integrations,
                  resend: {
                    ...state.data.integrations.resend,
                    apiKey,
                    domain,
                    fromAddress,
                  },
                },
              }
            : null,
        };
      });
    },
    currentCommitHash: get()?.data?.currentCommitHash ?? '',
    deployedCommitHash: '',
    selectedVersion: null,
    getMigrations: () => get()?.data?.integrations?.supabase?.migrations || [],
    setCurrentCommitHash: hash => set({ currentCommitHash: hash }),
    setData: data => set({ data }),
    setIsInspecting: isInspecting => set({ isInspecting }),
    setIsImageInspecting: isImageInspecting => set({ isImageInspecting }),
    setInspectedElements: elements => set({ inspectedElements: elements }),
    setRefetchWebapp: (date: number) => set({ refetchWebapp: date }),
    updateData: updates =>
      set(state => ({
        data: state.data ? { ...state.data, ...updates } : null,
      })),
    setIsLoading: isLoading => set({ isLoading }),
    setIsOwner: isOwner => set({ isOwner }),
    setSocket: socket => set({ socket }),
    setIsConnected: isConnected => set({ isConnected }),
    setFiles: files => {
      const { imageTracker } = get();
      set({ files });

      // Simple check for images when files are loaded
      if (files) {
        const initialRefs = imageTracker.findImageReferences(files);

        // Set isImageInCode based on whether images are found
        set({ isImageInCode: initialRefs.length > 0 });
      } else {
        // Reset when no files
        set({ isImageInCode: false });
      }

      // Existing sandpack state logic
      if (files && Object.keys(files).length > 0) {
        const currentSandpackState = get().sandpackState;
        if (
          !currentSandpackState ||
          !currentSandpackState.visibleFiles.length
        ) {
          set({
            sandpackState: {
              visibleFiles: Object.keys(files),
              activeFile: Object.keys(files)[0],
            },
          });
        }
      }
    },
    setDeployedCommitHash: hash => set({ deployedCommitHash: hash }),
    updateFile: (filePath, content) => {
      const { socket, isRemoteUpdate, checkImageChanges } = get();
      if (!isRemoteUpdate && socket) {
        socket.emit('updateFile', {
          filePath,
          content,
        });
      }
      checkImageChanges();
      set({ isRemoteUpdate: false });
    },
    setIframeRef: ref => set({ iframeRef: ref }),
    setSelectedVersion: version => set({ selectedVersion: version }),
    addInspectedElement: element =>
      set(state => ({
        inspectedElements: [...state.inspectedElements, element],
      })),
    removeInspectedElement: elementToRemove =>
      set(state => ({
        inspectedElements: state.inspectedElements.filter(
          element =>
            !(
              element.tagName === elementToRemove.tagName &&
              element.boundingBox.top === elementToRemove.boundingBox.top &&
              element.boundingBox.left === elementToRemove.boundingBox.left
            ),
        ),
      })),
    clearInspectedElements: () => set({ inspectedElements: [] }),
    setSupabaseProjectRef: ({ ref, organization_id, name, region }) => {
      set(state => ({
        data: state.data
          ? {
              ...state.data,
              integrations: {
                ...state.data.integrations,
                supabase: {
                  ref,
                  organization_id,
                  name,
                  region,
                },
              },
            }
          : null,
      }));
    },
    isSupabasePopupOpen: false,
    setIsSupabasePopupOpen: isOpen => set({ isSupabasePopupOpen: isOpen }),
    updateSupabaseMigrationStatus: (migrationId, status) => {
      set(state => {
        if (!state.data?.integrations?.supabase?.migrations) return state;

        const updatedMigrations =
          state.data.integrations.supabase.migrations.map(migration => {
            if (migration.id === migrationId) {
              return {
                ...migration,
                status,
                ...(status === 'APPLIED'
                  ? { appliedAt: new Date() }
                  : status === 'DISCARDED'
                    ? { discardedAt: new Date() }
                    : {}),
              };
            }
            return migration;
          });

        return {
          data: state.data
            ? {
                ...state.data,
                integrations: {
                  ...state.data.integrations,
                  supabase: {
                    ...state.data.integrations.supabase,
                    migrations: updatedMigrations,
                  },
                },
              }
            : null,
        };
      });
    },
    setNewMigrations: migrations => {
      set(state => {
        if (!state.data?.integrations?.supabase) return state;
        const newMigrations =
          state.data?.integrations?.supabase?.migrations || [];
        return {
          data: state.data
            ? {
                ...state.data,
                integrations: {
                  ...state.data.integrations,
                  supabase: {
                    ...state.data.integrations?.supabase,
                    migrations: [...newMigrations, ...migrations],
                  },
                },
              }
            : null,
        };
      });
    },
    isDraggingFile: false,
    setIsDraggingFile: isDragging => set({ isDraggingFile: isDragging }),
    sandpackState: null,
    setSandpackState: state => {
      set({ sandpackState: state });
    },
    resetSandpackState: () => {
      set({
        sandpackState: {
          visibleFiles: [],
          activeFile: '',
        },
      });
    },
    handleGenerate404: data => {
      set(state => ({
        data: state.data
          ? {
              ...state.data,
              generation404: {
                isActive: true,
                ...data,
              },
            }
          : null,
      }));
    },
    resetGenerate404: () => {
      set(state => ({
        data: state.data
          ? {
              ...state.data,
              generation404: undefined,
            }
          : null,
      }));
    },
    setAutoAcceptMigrations(autoAccept) {
      set(state => {
        if (!state.data?.integrations?.supabase) return state;
        return {
          data: state.data
            ? {
                ...state.data,
                integrations: {
                  ...state.data.integrations,
                  supabase: {
                    ...state.data.integrations.supabase,
                    auto_accept_migrations: autoAccept,
                  },
                },
              }
            : null,
        };
      });
    },
    getAutoAcceptMigrationsValue: () =>
      get()?.data?.integrations?.supabase?.auto_accept_migrations || true,
    editNavigationUrl: undefined,
    setEditNavigationUrl: (url?: string) => set({ editNavigationUrl: url }),
    setPhase: phase =>
      set(state => ({
        data: state.data
          ? {
              ...state.data,
              phase,
            }
          : null,
      })),
    tokenStats: null,
    setTokenStats: stats => set({ tokenStats: stats }),
    imageTracker: new ImageTracker(),
    imageChanges: null,
    setImageChanges: changes => set({ imageChanges: changes }),
    checkImageChanges: () => {
      const { files, imageTracker } = get();
      if (!files) return;

      const changes = imageTracker.getImageChanges(files);

      if (changes.added.length > 0 || changes.removed.length > 0) {
        set({
          imageChanges: changes,
          isImageInCode: true,
        });
      }
    },
    isImageInCode: false,
    setIsImageInCode: hasImages => set({ isImageInCode: hasImages }),
    hasUserAppliedImage: false,
    setHasUserAppliedImage: hasApplied =>
      set({ hasUserAppliedImage: hasApplied }),
    previewingVersionUrl: null,
    setPreviewingVersionUrl: url => set({ previewingVersionUrl: url }),
    setUrl: url =>
      set(state => {
        if (state.data) {
          return {
            data: {
              ...state.data,
              link: url,
            },
          };
        }
        return state;
      }),
    selectedImageMappings: [],
    pendingTabChange: null,
    showDiscardDialog: false,
    setSelectedImageMappings: mappings =>
      set(state => ({
        selectedImageMappings:
          typeof mappings === 'function'
            ? mappings(state.selectedImageMappings)
            : mappings,
      })),
    setPendingTabChange: tab => set({ pendingTabChange: tab }),
    setShowDiscardDialog: show => set({ showDiscardDialog: show }),
    selectedVideoMappings: [],
    setSelectedVideoMappings: mappings =>
      set(state => ({
        selectedVideoMappings:
          typeof mappings === 'function'
            ? mappings(state.selectedVideoMappings)
            : mappings,
      })),
    isHovering: false,
    setIsHovering: isHovering => set({ isHovering }),
    activeIntegrations: [],
    setActiveIntegrations: integrations =>
      set({ activeIntegrations: integrations }),
    setPrivacyStatus: (isPrivate: boolean) =>
      set(state => ({
        data: state.data
          ? {
              ...state.data,
              isPrivate,
            }
          : null,
      })),
    suggestions: [],
    lastAssistantMessageId: null,
    setSuggestions: (suggestions: Suggestion[]) =>
      set(state => ({ ...state, suggestions })),
    setLastAssistantMessageId: (lastAssistantMessageId: string | null) =>
      set(state => ({ ...state, lastAssistantMessageId })),
    mode: 'build',
    setMode: mode => set({ mode }),
  }),
);

// Add a new function to initialize socket
export function initializeSocket(url: string, webId: string) {
  const store = useWebappStore.getState();

  if (activeConnections.has(webId)) {
    store.setSocket(activeConnections.get(webId)!);
    store.setIsConnected(true);
    return;
  }

  const socket = io(url, {
    reconnection: true,
    reconnectionAttempts: 20,
    reconnectionDelay: 1000,
    query: { webId },
    autoConnect: true,
  });

  activeConnections.set(webId, socket);
  store.setSocket(socket);

  socket.on('connect', () => {
    store.setIsConnected(true);
  });

  socket.on('filesData', (filesFromServer: any) => {
    if (filesFromServer) {
      if (filesFromServer.error) {
        if (filesFromServer.error === 'FOLDER_NOT_FOUND') {
          store.setFiles(null);
        }
      } else if (Object.keys(filesFromServer).length > 0) {
        store.setFiles(filesFromServer);
      } else {
        store.setFiles(null);
      }
    } else {
      store.setFiles(null);
    }
  });

  socket.on('fileChanged', ({ relativePath, content }) => {
    const currentFiles = useWebappStore.getState().files || {};
    store.setFiles({
      ...currentFiles,
      [relativePath]: {
        ...currentFiles[relativePath],
        code: content,
      },
    });
  });

  socket.on('disconnect', () => {
    store.setIsConnected(false);
  });

  return () => {
    socket.disconnect();
    activeConnections.delete(webId);
    store.setSocket(null);
    store.setIsConnected(false);
  };
}
