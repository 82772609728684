import { create } from 'zustand';

interface KnowledgeBaseStore {
  isOpen: boolean;
  knowledge: string;
  setIsOpen: (isOpen: boolean) => void;
  setKnowledge: (knowledge: string) => void;
  reset: () => void;
}

export const useKnowledgeBaseStore = create<KnowledgeBaseStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen }),
  knowledge: '',
  setKnowledge: knowledge => set({ knowledge }),
  reset: () => set({ isOpen: false, knowledge: '' }),
}));
