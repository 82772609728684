import { POSTHOG_EVENTS } from '@/mixpanel/events';
import posthog from 'posthog-js';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'sonner';
import { useAuth } from '@clerk/nextjs';

interface UseEnhancePromptProps {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  setPrompt: (value: string) => void;
}

export const useEnhancePrompt = ({
  textareaRef,
  setPrompt,
}: UseEnhancePromptProps) => {
  const [enhanceState, setEnhanceState] = useState<
    'idle' | 'enhancing' | 'enhanced'
  >('idle');
  const userInteractedRef = useRef(false);
  const isStreamingRef = useRef(false);
  const resizeTimeoutRef = useRef<NodeJS.Timeout>();
  const previousHeightRef = useRef<number>(0);

  const { getToken } = useAuth();

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const handleUserInteraction = () => {
      userInteractedRef.current = true;
    };

    textarea.addEventListener('mousedown', handleUserInteraction);
    textarea.addEventListener('touchstart', handleUserInteraction);
    textarea.addEventListener('keydown', handleUserInteraction);
    textarea.addEventListener('wheel', handleUserInteraction);

    // Set initial height
    requestAnimationFrame(() => {
      adjustTextareaHeight();
    });

    return () => {
      textarea.removeEventListener('mousedown', handleUserInteraction);
      textarea.removeEventListener('touchstart', handleUserInteraction);
      textarea.removeEventListener('keydown', handleUserInteraction);
      textarea.removeEventListener('wheel', handleUserInteraction);

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [textareaRef]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    // Store current scroll and selection state
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const scrollTop = textarea.scrollTop;

    // Temporarily reset height to calculate proper scrollHeight
    textarea.style.height = 'auto';

    // Get the scroll height and apply constraints
    const scrollHeight = textarea.scrollHeight;
    const maxHeight = 200;
    const newHeight = Math.max(Math.min(scrollHeight, maxHeight), 56); // Minimum height of 56px

    // Only update height if it's significantly different
    if (Math.abs(newHeight - previousHeightRef.current) > 1) {
      textarea.style.height = `${newHeight}px`;
      previousHeightRef.current = newHeight;
    } else {
      textarea.style.height = `${previousHeightRef.current}px`;
    }

    // Handle scrolling behavior
    if (!userInteractedRef.current && isStreamingRef.current) {
      requestAnimationFrame(() => {
        textarea.scrollTop = textarea.scrollHeight;
      });
    } else {
      textarea.scrollTop = scrollTop;
    }

    // Restore selection
    textarea.setSelectionRange(selectionStart, selectionEnd);

    // Debounce additional resize checks
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeTimeoutRef.current = setTimeout(() => {
      const currentScrollHeight = textarea.scrollHeight;
      if (Math.abs(currentScrollHeight - scrollHeight) > 1) {
        adjustTextareaHeight();
      }
    }, 100);
  };

  const handleEnhancePrompt = async (options?: {
    isGeneration?: boolean;
    webId?: string | null;
  }) => {
    const currentPrompt = textareaRef.current?.value;
    if (!currentPrompt?.trim()) return;

    try {
      setEnhanceState('enhancing');
      isStreamingRef.current = true;
      userInteractedRef.current = false;

      const userToken = await getToken();

      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:4000'}/api/web/enhance-prompt/stream`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            prompt: currentPrompt,
            isGeneration: options?.isGeneration ?? true,
            webId: options?.webId ?? null,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to enhance prompt');
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      if (!reader) return;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const messages = chunk
          .split('\n\n')
          .filter(msg => msg.trim() !== '' && msg.trim() !== 'data: [DONE]');

        for (const message of messages) {
          if (message.startsWith('data: ')) {
            try {
              const data = JSON.parse(message.slice(6));
              if (data.text) {
                buffer += data.text;
                setPrompt(buffer);

                // Use RAF to ensure smooth height adjustments
                requestAnimationFrame(() => {
                  adjustTextareaHeight();
                });
              }
            } catch (e) {
              console.error('Error parsing SSE message:', e);
            }
          }
        }
      }

      // Final updates
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(buffer.length, buffer.length);

        // Ensure final height is correct
        requestAnimationFrame(() => {
          adjustTextareaHeight();
        });
      }

      isStreamingRef.current = false;
      setEnhanceState('enhanced');
      setTimeout(() => setEnhanceState('idle'), 2000);
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      isStreamingRef.current = false;
      setEnhanceState('idle');
      toast.error('Failed to enhance prompt');
    }
  };

  return {
    enhanceState,
    handleEnhancePrompt,
  };
};
